@import "antd/dist/antd.css";

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W0.ttc");
  font-weight: 0;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W1.ttc");
  font-weight: 100;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W2.ttc");
  font-weight: 200;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W3.ttc");
  font-weight: 300;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W4.ttc");
  font-weight: 400;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W5.ttc");
  font-weight: 500;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W6.ttc");
  font-weight: 600;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W7.ttc");
  font-weight: 700;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W8.ttc");
  font-weight: 800;
}

@font-face {
  font-family: "Hiragino Sans";
  src: url("../assets/fonts/Hiragino_Sans/ヒラギノ角ゴシック\ W9.ttc");
  font-weight: 900;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Thin.ttf");
  font-weight: 100;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-ThinItalic.ttf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-ExtraLightItalic.ttf");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-MediumItalic.ttf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-SemiBoldItalic.ttf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-BoldItalic.ttf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-ExtraBoldItalic.ttf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Barlow";
  src: url("../assets/fonts/Barlow/Barlow-BlackItalic.ttf");
  font-weight: 900;
  font-style: italic;
}

* {
  font-family: "Hiragino Sans";
}

#root {
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-64 {
  margin-top: 64px;
}

.ant-image-preview-mask {
  background: rgba(0, 0, 0, 0.85);
}

.ant-modal-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-modal-centered::before {
  content: unset;
}

.ant-image-preview-operations-progress {
  display: none;
}
